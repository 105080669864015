
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { Auto } from '@/entities/public'
  import { Stock } from '@/entities/sales'
  import { UNAVAILABLE_PHOTO } from '@/store/vehicles/vehicle'

@Component({})
  export default class StockPhotos extends FilesProcess {
  @Prop({ type: Boolean, default: false }) isPadding?: boolean;
  @Prop({ type: Boolean, default: true }) displayCarrousel?: boolean;
  @Prop({ type: Boolean, default: true }) displayReference?: boolean;
  @Prop({ type: Object, default: () => null }) auto?: Auto;
  @Prop({ type: Object, default: () => null }) stock?: Stock;
  @Prop({ type: Object, default: () => null }) item?: Record<string, any>;
  @Prop({ type: Boolean, default: true }) findStock?: boolean;
  @Prop({ type: Boolean, default: false }) fullImage?: boolean;

  photo = UNAVAILABLE_PHOTO
  photos = [];
  versionYearPhoto = [];
  selectedPhotoIndex: number | string = 0

  beforeDestroy () {
    this.photos = []
    this.versionYearPhoto = []
  }

  async mounted () {
    const { stock, item, auto } = this

    if (stock || item instanceof Stock) {
      await this.getPhotos(stock?.id || item?.id)
    }

    if (!this.photos.length) {
      await this.findVersionYearPhoto()
    }

    if (auto || item instanceof Auto) {
      await this.getStock(auto?.id)
    }
  }

  async getStock (autoId) {
    if (!autoId) return

    const stockId = await this.findStockId(autoId)

    if (stockId) {
      await this.getPhotos(stockId)
    }
  }

  async findVersionYearPhoto () {
    const { auto, stock, item } = this
    let versionYear = null

    if (auto || item instanceof Auto) {
      versionYear = auto?.version.id
    } else if (stock || item instanceof Stock) {
      versionYear = stock?.auto?.version.id
    }

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'version_year' } },
    })
    if (!versionYear) return

    const photo = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: versionYear } },
          { parameter: { process: { id: { _eq: process[0].id } } } },
          { parameter: { name: { _eq: 'version_model_photo' } } },
        ],
      },
      force: true,
    })

    this.versionYearPhoto = this.mapFiles(photo)
  }

  mapFiles (photos) {
    return photos.map(fileObj => ({
      id: fileObj.file.id,
      src: fileObj.file.uri,
      type: fileObj.file.type,
      name: fileObj.file.name,
      size: fileObj.file.checksum,
      idFileProcess: fileObj.id,
      isFileExpired: fileObj.file.isFileExpired,
      sourceLink: fileObj.file.sourceLink,
      error: fileObj.file.error,
      smallSrc: fileObj.file.smallUrl,
      largeSrc: fileObj.file.largeUrl,
    }))
  }

  async getPhotos (id) {
    if (!id) return
    const { displayCarrousel } = this
    const filter = {
      _and: [
        { process: { table_name: { _eq: 'stock' } } },
        { file_type: { name: { _eq: 'photo' } } },
        { name: { _eq: 'right_front' } },
      ],
    }

    if (displayCarrousel) {
      filter._and.pop()
    }

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter,
    })

    const fileParametersPhoto = photos.sort((a, b) => a.visibility?.publications?.order - b.visibility?.publications?.order)

    const newPhotos = []

    const handleComponentChange = async parameter => {
      const photosFetched = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: id } },
            { id_file_parameter: { _eq: parameter.id } },
          ],
        },
        force: true,
      })

      if (photosFetched.length) {
        newPhotos.push(...photosFetched)
      }
    }
    for (const parameter of fileParametersPhoto) {
      await handleComponentChange(parameter)
    }

    this.photos = this.mapFiles(newPhotos)
  }

  get vehicle () {
    const { stock, auto, findStock } = this

    return {
      auto,
      stock,
      findStock,
    }
  }

  @Watch('vehicle', { immediate: true, deep: true })
  async onVehicleChange (val) {
    if (!val?.auto?.id && !val?.stock?.id) return

    if (!val?.findStock) {
      return
    }

    if (val?.auto?.id && val?.stock?.id) {
      await this.getPhotos(val.stock.version.id)
    }

    if (!val?.stock?.id) {
      const stockId = await this.findStockId(val.auto.id)

      if (stockId) {
        await this.getPhotos(stockId)
      }
    }
  }
  }
