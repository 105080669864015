
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import _ from 'lodash'

@Component({})
  export default class LoanApplications extends BaseChart {
  @Prop({ type: String, default: null }) title
  @Prop({ type: String, default: null }) errorTitle
  @Prop() loanEvaluations: Record<string, any>
  statuses = []

  // Methods
  getData () {
    const loanEvaluations = _.cloneDeep(this.loanEvaluations)
    // status.isClosed == usar closing reason
    if (!loanEvaluations?.[0]) return

    const closedEvaluations = loanEvaluations.filter(loan => {
      return loan.evaluation?.status?.isClosed
    })

    const openEvaluations = loanEvaluations.filter(loan => {
      return !loan.evaluation?.status?.isClosed
    })
    const unsortedStatuses = []
    closedEvaluations.forEach(loan => {
      const name = loan.evaluation?.evaluation?.closingReason?.name
      let title = loan.evaluation?.evaluation?.closingReason?.title
      let color = loan.evaluation?.evaluation?.closingReason?.color
      if (!color) color = '#737373'

      if (loan.evaluation?.status?.higherClosingReason.isFinancialMisbehavior) {
        title = 'Rechazada'
        color = '#F03738'
      }

      if (!unsortedStatuses
        .map(status => status.name)
        .includes(name)) {
        unsortedStatuses.push({
          name,
          title,
          color: color.split(' ')[0],
        })
      }
    })
    openEvaluations.forEach(loan => {
      const name = loan.evaluation?.status?.name
      let title = loan.evaluation?.status?.title
      let color = loan.evaluation?.status?.color
      if (!color) color = '#737373'

      if (loan.evaluation?.status?.isFinancialMisbehavior) {
        title = 'Rechazada'
        color = '#F03738'
      }

      if (!unsortedStatuses
        .map(status => status.name)
        .includes(name)) {
        unsortedStatuses.push({
          name,
          title,
          color: color.split(' ')[0],
        })
      }
    })

    let statuses = []
    statuses.push(unsortedStatuses.filter(status => status.name === 'signed')?.[0])
    statuses.push(unsortedStatuses.filter(status => status.name === 'approved')?.[0])
    statuses.push(unsortedStatuses.filter(status => status.name === 'resigned')?.[0])
    statuses.push(unsortedStatuses.filter(status => status.name === 'conditioned')?.[0])
    statuses.push(unsortedStatuses.filter(status => status.name === 'rejected')?.[0])
    statuses.push(unsortedStatuses.filter(status => status.name === 'pending')?.[0])
    statuses = statuses.filter(status => status)

    this.series = []

    statuses
      .map(status => status?.name)
      .forEach(status => {
        let openSum = 0
        let closedSum = 0

        closedSum += closedEvaluations.filter(evaluation => {
          const evaluationStatus = evaluation.evaluation?.evaluation?.closingReason.name
          return evaluationStatus === status
        }).length
        openSum += openEvaluations.filter(evaluation => {
          const sorted = evaluation.evaluations.sort((prev, next) => {
            return next.status.order - prev.status.order
          })
          const evaluationStatus = sorted[0].status.name
          return evaluationStatus === status
        }).length

        this.series.push(openSum + closedSum)
      })

    this.statuses = statuses

    this.chartOptions = {
      labels: statuses.map(status => status?.title),
      colors: statuses.map(status => status?.color),
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 1,
          },
        },
      },
    }
  }

  // Getters
  get applicationsNumber () {
    let sum = 0
    this.series.forEach(el => {
      sum += el
    })
    return sum
  }

  get withoutLoans () {
    return !this.loanEvaluations?.length
  }

  // Watchers
  @Watch('loanEvaluations', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
