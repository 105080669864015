
  import { Component } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { GForm } from '@/components/forms/GForm'
  import GenericStockDetail from '@/components/stock/GenericStockDetail.vue'
  import SupervisorStockDetail from '@/components/stock/SupervisorStockDetail.vue'
  import GLoading from '@/components/core/GLoading.vue'

@Component({
  components: {
    GLoading,
    SupervisorStockDetail,
    GenericStockDetail,
  },
  computed: {
    ...mapGetters('user', ['roles', 'defaultRole']),
  },
})
  export default class StockDetails extends GForm {
  roles!: string[]
  defaultRole!: string
  displayDetails = false
  stockId = null
  documentsAllowed = {}

  get displayCharts () {
    const { roles, defaultRole } = this

    return defaultRole === 'admin' || roles.some(r => r.slug.includes('supervisor'))
  }

  async created () {
    const { uid: id } = this

    if (!id) this.$router.back()

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'stock' } },
    })

    this.documentsAllowed = process?.[0]?.config?.sharedDocuments

    this.stockId = id
  }
  }
