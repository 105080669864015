
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { Lead } from '@/entities/crm'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})

  export default class LeadsByPipelinePie extends BaseChart {
  @Prop({ type: String, default: null }) title
  @Prop({ type: Boolean, default: true }) showTitle
  @Prop({ type: Object, default: () => ({}) }) filter: Record<string, any>
  @Prop({ default: undefined, type: Number }) idExecutive
  leads: Lead[]
  seriesData = []
  leadsTotal = 0
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  // Methods
  getExecutivesByStatus (status: string) {
    const executives = []
    const byStatus = this.leads.filter(lead => status === 'Leads cerrado' ? lead.closedAlert : (lead.activityAlert?.tooltip === status && !lead.closedAlert))
    byStatus.forEach(lead => {
      if (!executives.map(executive => executive?.id).includes(lead.executive?.id)) {
        executives.push(lead.executive)
      }
    })
    const leadsByExecutive = executives.map(executive => {
      return {
        name: executive?.person?.firstName + ' ' + executive?.person?.surname,
        leads: byStatus.filter(lead => lead.executive?.id === executive?.id),
      }
    })
    leadsByExecutive.forEach(executive => {
      if (executive.name === 'undefined undefined') {
        executive.name = 'SIN EJECUTIVO'
      }
    })
    return leadsByExecutive.sort((prev, next) => next.leads.length - prev.leads.length)
  }

  getLeadsByActivityAlert () {
    const { leads } = this
    if (!leads) return

    const activityAlerts = []
    const activityCounts = {}

    leads.forEach(lead => {
      const alert = lead.activityAlert

      if (alert && !lead.closedAlert) { // Ignorar leads con closedAlert activo
        if (!activityCounts[alert.tooltip]) {
          activityCounts[alert.tooltip] = 0
          activityAlerts.push({ tooltip: alert.tooltip, color: alert.colorHexadecimal })
        }
        activityCounts[alert.tooltip]++
      }

      if (lead.closedAlert) {
        if (!activityCounts[lead.closedAlert.tooltip]) {
          activityAlerts.push({ tooltip: lead.closedAlert.tooltip, color: lead.closedAlert.colorHexadecimal })
          activityCounts[lead.closedAlert.tooltip] = 0
        }
        activityCounts[lead.closedAlert.tooltip]++
      }
    })

    this.series = activityAlerts.map(alert => activityCounts[alert.tooltip])

    this.chartOptions = {
      colors: activityAlerts.map(alert => alert.color),
      chart: {
        type: 'pie',
      },
      labels: activityAlerts.map(alert => alert.tooltip),
      title: {
        text: this.title ? '' : 'Leads por Alerta de Actividad',
        style: {
          fontFamily: 'Poppins',
        },
      },
      tooltip: {
        pointFormat: '{point.name}: <b>{point.y} tareas</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y} tareas',
          },
        },
      },
      legend: {
        show: false,
      },
    }

    this.seriesData = activityAlerts.map((alert, index) => ({
      name: alert.tooltip,
      y: this.series[index],
      color: alert.color,
    }))
  }

  // Getters

  // Watchers
  @Watch('filter', { immediate: true, deep: true })
  async onFilterChange () {
    const { filter } = this
    this.display = false
    if (!filter) return

    this.leads = await this.fetchData({
      query: { name: 'findLite', model: 'Lead' },
      filter,
      force: true,
    })
    this.leadsTotal = this.leads.length
    if (this.leads.length) {
      this.getLeadsByActivityAlert()
    }
    this.display = true
  }
  }
