
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import dayjs from 'dayjs'
  import { channelColors } from '@/utils/channelColors'

@Component({})
  export default class LeadsByChannel extends BaseChart {
  @Prop() leads: Record<string, any>
  @Prop() prevMonthLeads: Record<string, any>
  @Prop() dates
  @Prop({ type: String, default: null }) title
  channels = []
  channelColors = channelColors

  // Methods

  toggleSerie (serie) {
    const notHide = [serie, serie + ' (mes anterior)']
    this.channels.forEach(channel => {
      if (notHide.includes(channel.description)) {
        this.$refs.chart.showSeries(channel.description)
        this.$refs.chart.showSeries(channel.description + ' (mes anterior)')
      } else {
        this.$refs.chart.hideSeries(channel.description)
        this.$refs.chart.hideSeries(channel.description + ' (mes anterior)')
      }
    })
  }

  getData () {
    if (!this.leads || !this.prevMonthLeads) return
    const leads = this.leads.records
    const prevMonthLeads = this.prevMonthLeads.records

    if (!leads || !prevMonthLeads) return
    const categories = this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    ).slice(0, -1)
    const prevMonthRange = this.generateDayRange(
      dayjs(this.dates.start).subtract(1, 'month').startOf('month'),
      dayjs(this.dates.start).subtract(1, 'month').endOf('month')
    ).slice(0,
      this.generateDayRange(dayjs(this.dates.start), dayjs(this.dates.start).endOf('month')).length - 1
    )

    const channels = []
    const allLeads = [...leads, ...prevMonthLeads]
    allLeads.forEach(lead => {
      if (!lead.deals[0]) return
      if (!channels.map(channel => channel.name).includes(lead.deals[0].channel.name)) {
        channels.push(lead.deals[0].channel)
      }
    })
    this.channels = channels

    const leadData = channels.map(channel => {
      const data = []
      categories.forEach(category => {
        const filteredLeads = leads.filter(lead => {
          return dayjs(this.convertUtc(lead.created_at)).isSame(dayjs(category), 'day') &&
            lead.deals?.[0]?.channel?.name === channel.name
        })
        data.push(filteredLeads.length)
      })
      return {
        name: channel.description,
        data,
        color: channelColors[channel.name],
        type: 'line',
      }
    })

    const prevMonthData = channels.map(channel => {
      const data = prevMonthRange.map(category => {
        return prevMonthLeads.filter(lead => {
          return dayjs(this.convertUtc(lead.created_at)).isSame(dayjs(category), 'day') &&
            lead.deals?.[0]?.channel?.name === channel.name
        }).length
      })
      return {
        name: `${channel.description} (mes anterior)`,
        data,
        color: channelColors[channel.name] + '4C',
        type: 'line',
      }
    })

    this.series = [...leadData, ...prevMonthData]

    this.series.push({
      name: 'Total',
      data: categories.map(category => {
        return leads.filter(lead => {
          return dayjs(this.convertUtc(lead.created_at)).isSame(dayjs(category), 'day')
        }).length
      }),
      type: 'area',
      color: '#DCFCE7',
    })

    let maxSum = this.series
      .map(serie => serie.data)
      .flat()
      .sort((a, b) => b - a)[0]

    if (maxSum % 2 !== 0) maxSum++

    this.chartOptions = {
      chart: {
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD/MM')
          },
        },
      },
      title: {
        text: this.title ? '' : 'Por canal',
        style: {
          fontFamily: 'Poppins',
        },
      },
      yaxis: {
        min: 0,
        max: maxSum,
        forceNiceScale: true,
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  }

  // Getters
  get withOutLeads () {
    return !this.leads?.records?.length
  }

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  @Watch('prevMonthLeads', { immediate: true, deep: true })
  @Watch('dates', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
